@import  "../../App.scss";


.section3{
    padding-bottom: 5%;
    h1{
        margin-top: 10%;
        margin-bottom:2%;
    }
    .pr-card-head{
        margin: 10% 5%;
        .pr-card{
            text-align: center;
            background-color: #00f7ff13;
            padding:3% 3%;
            h2{
                color: $primaryColor;
                font-weight: 300;
                margin-bottom: 8%;
            }
            p{
                margin-bottom: 5%;
                line-height: 1.8;
            }
        }
    }
}


@media (max-width: 992px) {

}
@media (max-width: 768px) {


}
@media (max-width: 576px) {
.section3{
    .pr-card-head{
        margin: 10% 5%;
        .pr-card{
        margin: 8% 2%;
        padding:10%;
        }
    }
}
}