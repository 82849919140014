$primaryColor:#00F6FF;
$secondaryColor:#8000FF;

$primary-font:'Montserrat', sans-serif;
$secondary-font:'Source Sans Pro', sans-serif;

h1,h2,h3,h5{
  font-family: $primary-font;
}
.heading{
  text-align: center;
  font-size: 3em;
  color: $primaryColor;
}


::-webkit-scrollbar{
  width:5px !important;
}

::-webkit-scrollbar-track{
  background:transparent !important;
}


::-webkit-scrollbar-thumb{
  background:rgb(235, 235, 235) !important;
  opacity:0.2;
  border-radius:25px
}

::-webkit-scrollbar-thumb:hover{
  background:#5a1e96 !important;
}


@media (max-width: 992px) {

}
@media (max-width: 768px) {


}
@media (max-width: 576px) {
  .alert{
    position: absolute;
    bottom: 0;
    z-index: 5;
    left: 0%;
  }
  
}