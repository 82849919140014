@import  "../../App.scss";

.footer{
    margin-top: 8%;
    background-color: #00f7ff6e;
    color: white;
    padding:1% 2%;
    h3{
        font-size: 1.2em;
        font-weight: 300;
    }
    p{
        font-size: 1em;
    }
}


@media (max-width: 992px) {

}
@media (max-width: 768px) {


}
@media (max-width: 576px) {
.footer{
    .phone{
        margin:10%;
    }
}
}