@import  "../../App.scss";

.ourmoto{
    text-align: center;
    
    h1{
        margin-bottom: 2%;
    }p{
        padding:1% 20%;
        line-height: 2;
    }
}