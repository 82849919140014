@import  "../../App.scss";

.contact-us{
    h1{
        text-align: center;
        color: $primaryColor;
        font-size: 3em;
        margin-top: 2%;
    }
    form{
        background-color: #00f7ff13;
        margin: 1% 25%;
        padding:2% 1%;
        .form-input{
            margin:4% auto;
            width: 80%;
            label{
                display: block;
            }
            input{
                padding:2% 4%;
                background-color: transparent;
                border: 1px solid #00f7ff9a;
                width: 100%;
                color:#e2e2e2;
            }
            input:focus{
                border: 1px solid #00f7ff;
                outline: none;
            }
        }

    }
}